import revive_payload_client_4sVQNw7RlN from "/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import fontawesome_7xXmPH2btU from "/frontend/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.js";
import _010_exposeEnv_ouY5V7hXOt from "/frontend/plugins/010.exposeEnv.js";
import _020_exposeVue_S7daEIKWKi from "/frontend/plugins/020.exposeVue.js";
import _060_maska_6MPF13Gef8 from "/frontend/plugins/060.maska.ts";
import _065_log_Ifc4bLM3SZ from "/frontend/plugins/065.log.js";
import _066_lendio_cookies_4XDHvemeHr from "/frontend/plugins/066.lendio-cookies.js";
import _070_axios_GRhni13UQX from "/frontend/plugins/070.axios.js";
import _071_axiosHandler_dSJ3RlGJiS from "/frontend/plugins/071.axiosHandler.js";
import _083_pinia_Rb4xtPKtst from "/frontend/plugins/083.pinia.js";
import _085_exposePinia_YIeZaq9gv9 from "/frontend/plugins/085.exposePinia.js";
import _086_iframe_only_check_client_vcht0GJTX5 from "/frontend/plugins/086.iframe-only-check.client.js";
import _087_fetch_affiliate_customizations_UHcAnvRWlL from "/frontend/plugins/087.fetch-affiliate-customizations.js";
import _100_checkEsignature_client_W2T7eUrLMe from "/frontend/plugins/100.checkEsignature.client.js";
import _120_chat_service_client_4yFKaluvIK from "/frontend/plugins/120.chat-service.client.js";
import _120_routeChange_client_HAb1w7sMOU from "/frontend/plugins/120.routeChange.client.js";
import _140_datadog_client_LA1YMf0ckj from "/frontend/plugins/140.datadog.client.js";
import _150_analytics_init_oQe1JLjCxq from "/frontend/plugins/150.analytics-init.js";
import _190_lendioStyleguideComponents_client_R1GUEinbit from "/frontend/plugins/190.lendioStyleguideComponents.client.js";
import _210_app_hooks_8JMYAVUWhl from "/frontend/plugins/210.app-hooks.js";
import _220_lendio_embedded_client_L4rerX7N8x from "/frontend/plugins/220.lendio-embedded.client.js";
import _230_anonymous_ulid_2tC5ZZdrxd from "/frontend/plugins/230.anonymous-ulid.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  fontawesome_7xXmPH2btU,
  _010_exposeEnv_ouY5V7hXOt,
  _020_exposeVue_S7daEIKWKi,
  _060_maska_6MPF13Gef8,
  _065_log_Ifc4bLM3SZ,
  _066_lendio_cookies_4XDHvemeHr,
  _070_axios_GRhni13UQX,
  _071_axiosHandler_dSJ3RlGJiS,
  _083_pinia_Rb4xtPKtst,
  _085_exposePinia_YIeZaq9gv9,
  _086_iframe_only_check_client_vcht0GJTX5,
  _087_fetch_affiliate_customizations_UHcAnvRWlL,
  _100_checkEsignature_client_W2T7eUrLMe,
  _120_chat_service_client_4yFKaluvIK,
  _120_routeChange_client_HAb1w7sMOU,
  _140_datadog_client_LA1YMf0ckj,
  _150_analytics_init_oQe1JLjCxq,
  _190_lendioStyleguideComponents_client_R1GUEinbit,
  _210_app_hooks_8JMYAVUWhl,
  _220_lendio_embedded_client_L4rerX7N8x,
  _230_anonymous_ulid_2tC5ZZdrxd
]