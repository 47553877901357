import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useBorrowerStore } from '~/store/borrower'
import env from '~/libs/env'
import { extendOption } from '~/libs/offer-option-helper'

export const useOfferOptionsStore = defineStore('offerOptions', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp

  const borrowerStore = useBorrowerStore()

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const offerOptions = ref([])
  const loaded = ref(false)

/*
  ██████  ███████ ████████ ████████ ███████ ██████  ███████
 ██       ██         ██       ██    ██      ██   ██ ██
 ██   ███ █████      ██       ██    █████   ██████  ███████
 ██    ██ ██         ██       ██    ██      ██   ██      ██
  ██████  ███████    ██       ██    ███████ ██   ██ ███████
 GETTERS
*/
  const availableOptions = computed(() => {
    return offerOptions.value
      .map((option) => {

        return {
          ...option,
          paymentFrequency: option.termSet?.paymentFrequency,
          paymentCount: option.termSet?.paymentCount,
          paymentAmount: option.termSet?.paymentAmount,
          numPayments: option.termSet?.paymentCount,
          term: option.termSet?.term,
          position: option.termSet?.position,
          points: option.termSet?.points,
          commission: option.termSet?.commission,
          ...extendOption(option.loanProductCategoryType, option.termSet),

          isStaticOffer: true,
          isOfferOption: true,
        }
      })
  })

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  async function getOfferOptions(force = false) {
    // If we ain't got no borrower, don't proceed.
    const borrowerId = borrowerStore.borrowerId
    if (!borrowerId) return

    if (offerOptions.value.length && !force) {
      return
    }

    const { data, status } = await $axios.get(
      `${env('apiUrl')}/borrower/${borrowerId}/offer-options`
    )

    // Do nothing if status isn't 200 or there are no offer options available. State
    // above initializes offer options to [].
    const _offerOptions = data && data.data ? data.data : []
    if (status !== 200) {
      loaded.value = true
      return
    }

    // Remove un-needed information from offer options.
    offerOptions.value = _offerOptions.map((o) => {
      return {
        id: o.id,
        status: o.status,
        statusLabel: o.statusLabel,
        name: o.name,
        dealId: o.dealId,
        approvalId: o.approvalId,
        offerId: o.offerId,
        loanProductCategoryType: o.loanProductCategoryType,
        loanProductCategoryName: o.loanProductCategoryName,
        loanProductLenderId: o.loanProductLenderId,
        loanProductId: o.loanProductId,
        lenderName: o.lenderName,
        published: o.publishedToBorrower,
        isPrioritized: o.isPrioritized,
        termSet: o.termSet,
        created: o.created,
        modified: o.modified,
        deleted: o.deleted,
      }
    })
    loaded.value = true
  }

  return {
    //STATE
    offerOptions,
    loaded,
    //GETTERS
    availableOptions,
    //ACTIONS
    getOfferOptions,
  }
})
